import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function EventoDelMes() {
  const navigate = useNavigate();

  useEffect(() => {
    // Llamada al backend para obtener el último evento activo
    fetch(`${process.env.REACT_APP_API_URL}/ultimo-evento-activo/`)
      .then((response) => response.json())
      .then((data) => {
        // Asumiendo que 'data' contiene algo como { id: 17 }
        if (data?.id) {
          navigate(`/event/${data.id}`);
        } else {
          // Maneja el caso de no encontrar ningún evento
          // Podrías redirigir a /events o mostrar un mensaje
          navigate("/events");
        }
      })
      .catch((error) => {
        console.error("Error al obtener el evento:", error);
        // Maneja el error: quizás redirigir o mostrar un mensaje
        navigate("/events");
      });
  }, [navigate]);

  // Mientras cargamos o gestionamos la redirección, puedes mostrar un Loader o nada
  return <div>Cargando...</div>;
}

export default EventoDelMes;
