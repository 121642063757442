import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import EventoDelMes from "./pages/EventoDelMes";
import EventDetails from "./pages/EventDetails";
import EventList from "./pages/EventList";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Navbar from "./components/Navbar"; 
import SessionsPage from "./pages/SessionsPage";
import OrganizedPlay from "./pages/OrganizedPlay";
import CharacterDetail from "./pages/CharacterDetail";
import AddPlaythroughForm from "./pages/AddPlaythroughForm";
import PasswordReset from "./pages/PasswordReset";
import CreateCharacterForm from "./components/CreateCharacterForm";
import BulkAddPlaythroughForm from "./components/BulkAddPlaythroughForm";
import Testing from "./pages/Testing";
import Lena from "./pages/Lena";

function App() {
  return (
    <Router>
      {/* Navbar */}
      <Navbar />
      <div className="content">


        {/* Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/events/" element={<EventList />} />
          <Route path="/event/:id" element={<EventDetails />} />
          <Route path="/evento-del-mes" element={<EventoDelMes />} />
          <Route path="/sessions/event/:id" element={<SessionsPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/organizedplay" element={<OrganizedPlay />} />
          <Route path="/characters/:characterId" element={<CharacterDetail />} />
          <Route path="/characters/:characterId/add-playthrough" element={<AddPlaythroughForm />} />
          <Route path="/characters/:characterId/scenarios/:playthroughId" element={<AddPlaythroughForm />} />
          <Route path="/characters/create" element={<CreateCharacterForm />} />
          <Route path="/characters/:characterId/scenarios/bulk-add/" element={<BulkAddPlaythroughForm />} />
          <Route path="/dankas-test" element={<Testing />} />          
          <Route path="/Leni" element={<Lena hideNavbar={true} />} />          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
