import React, { useEffect } from 'react';

const PathfinderSection = () => {
  useEffect(() => {
    // Inicializar el comportamiento de los acordeones
    const accordionButtons = document.querySelectorAll('.accordion-button');
    accordionButtons.forEach(button => {
      if (button.classList.contains('collapsed')) {
        button.style.backgroundColor = '#ffffff'; // Estilo por defecto (cerrado)
        button.style.color = '#000'; // Color de texto por defecto
      } else {
        button.style.backgroundColor = '#353535'; // Estilo cuando está abierto
        button.style.color = '#fff'; // Color de texto cuando está abierto
      }
    });
  }, []);

  return (
    <section className="pathfinder-section">
   

      <div className="accordion mt-3 mb-3">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pf2factions">
              <span className='fs-5 fw-bold'>Facciones de Pathfinder 2e</span>
            </button>
          </h2>
          <div id="pf2factions" className="accordion-collapse collapse">
            <div className="accordion-body">
              <p>Al crear tu personaje en la Sociedad Pathfinder, debes elegir una facción interna a la que pertenecerá. Estas facciones han evolucionado para centrarse en promover los principios de la Sociedad, dejando de lado ambiciones externas. Se dividen en facciones mayores, con objetivos alineados directamente con la misión principal de la Sociedad como la exploración o la investigación, y facciones menores, que tienen metas más específicas o a corto plazo. La lealtad de los agentes siempre está orientada hacia la Sociedad Pathfinder.</p>
              <h2>Facciones mayores</h2>
              <div>
                <img src="https://cdn.paizo.com/d960ad1b-9967-00f9-1158-72274b18312d/7713c55a-f73e-41ca-b302-a2d117c79f44/031720_EnvoysAlliance.png?w=300" className="me-2 img-faction float-start" />        
                <h4 className="text-start">Alianza de los Enviados <a href="https://paizo.com/pathfinderSociety/factions/envoys-alliance" target="_blank">(Envoy's Alliance)</a></h4>
                <p className="mb-0"><b>Lema:</b> Fuerza en comunidad</p>
                <b>Resumen:</b> La Alianza de Enviados pretende reforzar las filas de la Sociedad y representar a sus miembros, haciendo que la Sociedad sea cada vez más fuerte, esté mejor formada y cuente con más apoyo.
              </div>
              <hr/>
              <div>
                <img src="https://cdn.paizo.com:443/d960ad1b-9967-00f9-1158-72274b18312d/effe7ffa-7fab-461d-b045-cc6e18b68acf/Faction_Grand-Archive.png?w=300" className="me-2 img-faction float-start" />        
                <h4 className="text-start">Gran Archivo <a href="https://paizo.com/pathfinderSociety/factions/grand-archive" target="_blank">(Grand Archive)</a></h4>
                <p className="mb-0"><b>Lema:</b> Documentando el Pasado</p>
                <b>Resumen:</b> La arqueología es una ciencia destructiva, y los aventureros suelen ser más destructivos que la mayoría. Si la Sociedad quiere mantener su mandato de estudiar y preservar el pasado, la documentación y el rigor académico son fundamentales.
              </div>
              <hr />

              <div>
                <img src="https://cdn.paizo.com:443/d960ad1b-9967-00f9-1158-72274b18312d/b8ccc0ad-9ccd-4b8c-a91a-7a5dbbdfdb04/031720_HorizonHunters.png?w=300" className="me-2 img-faction float-start" />        
                <h4 className="text-start">Cazadores de Horizontes <a href="https://paizo.com/pathfinderSociety/factions/horizon-hunters" target="_blank">(Horizon hunters)</a></h4>
                <p className="mb-0"><b>Lema:</b> La gloria se encuentra en el horizonte</p>
                <b>Resumen:</b> La facción de los Cazadores de Horizontes es el hogar de aquellos que mantienen la orgullosa tradición de ascender a los picos más altos, sondear las profundidades más oscuras y ver qué hay tras la siguiente colina.<br/>
                <b>Nota:</b> esta es la facción estándar de todo Pathfinder, si no eliges ninguna otra por defecto pertenecerás a esta.
              </div>
              <hr />

              <div>
                <img src="https://cdn.paizo.com:443/d960ad1b-9967-00f9-1158-72274b18312d/7ff9bc6f-c772-4e8c-817f-53b23348971e/061620_Faction_VigilantShield.png?w=300" className="me-2 img-faction float-start" />        
                <h4 className="text-start">Sello Vigilante <a href="https://paizo.com/pathfinderSociety/factions/vigilant-seal" target="_blank">(Vigilant Seal)</a></h4>
                <p className="mb-0"><b>Lema:</b> Proteger, Contener, Destruir</p>
                <b>Resumen:</b> Los miembros del Sello Vigilante tratan de garantizar que los males antiguos descubiertos durante sus expediciones se traten adecuadamente: protegen los yacimientos antiguos, contener las posibles amenazas y destruir los males peligrosos que no pueden contenerse de forma segura.
              </div>
              <hr />
              <h2>Facciones menores</h2>
              <div>
                <img src="https://cdn.paizo.com:443/d960ad1b-9967-00f9-1158-72274b18312d/23653225-8ec9-4ac8-ab04-72822fb1a171/031720_RadiantOath.png?w=300" className="me-2 img-faction float-start" />        
                <h4 className="text-start">Juramento Radiante <a href="https://paizo.com/pathfinderSociety/factions/radiant-oath" target="_blank">(Radiant Oath)</a></h4>
                <p className="mb-0"><b>Lema:</b> Una luz en las sombras</p>
                <b>Resumen:</b> Los que se unen al Juramento Radiante hacen votos sencillos para ayudar a los demás, ampliando estas promesas cuanto más sirvan a la facción.
              </div>
              <hr />

              <div>
                <img src="https://cdn.paizo.com:443/d960ad1b-9967-00f9-1158-72274b18312d/2e736012-5434-4452-a4d3-708ac6a9e899/061620_Faction_VerdantWheel.png?w=300" className="me-2 img-faction float-start" />        
                <h4 className="text-start">Rueda Verdeante <a href="https://paizo.com/pathfinderSociety/factions/verdant-wheel" target="_blank">(Verdant Wheel)</a></h4>
                <p className="mb-0"><b>Lema:</b> Proteger, Contener, Destruir</p>
                <b>Resumen:</b> Los entusiastas de la naturaleza de todo tipo -desde rastreadores curtidos hasta ecologistas de ojos brillantes o druidas tenaces- acuden a la Sociedad en busca de oportunidades para presenciar maravillas naturales, catalogar nuevas especies y proteger mejor los prístinos reinos del mundo..
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="accordion mt-3 mb-3">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pf2links">
              <span className='fs-5 fw-bold'>Enlaces de interés PFS2e</span>
            </button>
          </h2>
          <div id="pf2links" className="accordion-collapse collapse ">
            <div className="accordion-body">
              <p className='mb-0'>Los siguientes enlaces pueden ser útiles en el Juego Organizado de Pathfinder 2e</p>
              <ul>
                <li className='mb-0 mt-0'><a href="https://lorespire.paizo.com/tiki-index.php?page=pfs2guide._.Full-Guide">Guía de Juego Organizado para Pathfinder 2e</a></li>
                <li className='mb-0 mt-0'><a href="https://paizo.com/pathfindersociety/characteroptions">Opciones de Personajes</a></li>
                <li className='mb-0 mt-0'><a href="https://paizo.com/pathfindersociety/faq">FAQs de Pathfinder</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PathfinderSection;
