import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import axios from "axios"; // Importamos axios para las solicitudes HTTP
import 'bootstrap/dist/css/bootstrap.min.css';
import Gallery from "../components/Gallery";

// Importación de imágenes para la galería
import imagen1 from "../assets/carousel_home/G1.jpg";
import imagen2 from "../assets/carousel_home/G2.jpg";
import imagen3 from "../assets/carousel_home/G3.jpg";
import imagen4 from "../assets/carousel_home/G4.jpg";
import imagen5 from "../assets/carousel_home/G5.jpg";
import imagen6 from "../assets/carousel_home/G6.jpg";
import imagen7 from "../assets/carousel_home/G7.jpg";
import imagen8 from "../assets/carousel_home/G8.jpg";
import imagen9 from "../assets/carousel_home/G9.jpg";

/**
 * Componente principal que muestra la página de inicio con los eventos más recientes y una galería de imágenes.
 */
function App() {
  // Estado para almacenar los eventos
  const [eventos, setEventos] = useState([]);
  const [error, setError] = useState(null); // Estado para manejar errores

  // Array con las imágenes para la galería
  const imagenes = [imagen1, imagen2, imagen3, imagen4, imagen5, imagen6, imagen7, imagen8, imagen9];

  /**
   * Hook useEffect que se ejecuta al montar el componente.
   * Hace la solicitud a la API para obtener los eventos más recientes.
   */
  useEffect(() => {
    const fetchEventos = async () => {
      try {
        // Realizamos la solicitud GET para obtener los eventos más recientes
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/last-events/`);
        setEventos(response.data); // Almacenamos los eventos obtenidos en el estado
      } catch (err) {
        console.error("Error al obtener los eventos:", err);
        setError("No se pudieron cargar los eventos. Por favor, inténtalo más tarde.");
      }
    };

    fetchEventos(); // Ejecutamos la función para obtener los eventos
  }, []); // El array vacío asegura que solo se ejecuta una vez al montar el componente

  return (
    <div>
      {/* Header */}
      <header className="bg-dark text-white text-center py-5">
        <div className="container">
          <h1 className="display-4 anton-regular">Sociedad Pathfinder <br/>de Madrid</h1>
          <p className="lead display fs-2 germania-one-regular">Explorar, reportar y cooperar</p>
        </div>
      </header>

      {/* About Section */}
      <section id="about" className="pt-3">
        <div className="container">
          <Fade triggerOnce>
            <h2 className="text-center mb-4">¿Quiénes somos?</h2>
            <p className="text-muted text-center">
              Somos la Sociedad Pathfinder de Madrid, una asociación dedicada a fomentar el Juego Organizado de Paizo a través de los modos de juego "Pathfinder Society" y "Starfinder Society". Organizamos eventos mensuales donde dirigimos partidas oficiales y autoconclusivas (one-shots) basadas en los sistemas de Pathfinder 2e y Starfinder 2e. Estas aventuras, de aproximadamente 4 horas de duración, siguen tanto las reglas de cada sistema como las normativas específicas del Juego Organizado.
            </p>
            <p className="text-muted text-center">
              Nuestra misión es reunir a jugadores y narradores para explorar mundos fantásticos y vivir emocionantes historias, todo ello de manera totalmente gratuita. ¡Únete a nosotros y sé parte de esta comunidad de aventureros y exploradores!
            </p>
          </Fade>
        </div>
      </section>

      {/* Events Section */}
      <section id="events" className="bg-light pt-2">
        <div className="container">
          <Fade direction="up" triggerOnce>
            <h2 className="text-center mb-4">Últimos eventos</h2>
            {/* Verificamos si hay un error o si existen eventos */}
            {error ? (
              <p className="text-center">{error}</p>
            ) : eventos.length > 0 ? (
              <div className="row">
                {/* Mapeamos los eventos y los mostramos en tarjetas */}
                {eventos.map((evento) => (
                  <div key={evento.id} className="col-md-6">
                    <Link to={`/event/${evento.id}`} className="text-decoration-none">
                      <div className="card mb-3 d-flex flex-row align-items-stretch">
                        <div className="row g-0 align-items-center">
                          {/* Columna de la imagen */}
                          <div className="col-4 col-md-4 d-none d-md-block">
                            {/* Imagen móvil */}
                            <img
                              src={evento.imagen}
                              className="img-fluid rounded-start h-100"
                              style={{ maxHeight: "500px" }}
                              alt={evento.titulo}
                            />
                          </div>
                          <div className="col-4 d-md-none">
                            {/* Imagen escritorio */}
                            <img
                              src={evento.imagen}
                              className="img-fluid rounded-start w-100 h-100 object-fit-cover"
                              alt={evento.titulo}
                            />
                          </div>
                          {/* Columna de los datos */}
                          <div className="col-8">
                            <div className="card-body">
                              <h5 className="card-title"><strong>{evento.titulo}</strong></h5>
                              <hr className="mb-1 mt-1" />
                              <p className="card-text mb-0" style={{ fontSize: "14px" }}><strong>Fecha:</strong> {evento.formatted_date}</p>
                              <p className="card-text mb-0" style={{ fontSize: "14px" }}><strong>Sistemas:</strong> {evento.sistemas?.join(", ")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center">No hay eventos disponibles en este momento.</p>
            )}
          </Fade>
        </div>
      </section>

      {/* Galería reutilizable */}
      <Fade direction="up" triggerOnce>
        <Gallery imagenes={imagenes} titulo="Imágenes de eventos" />
      </Fade>

      {/* Footer */}
      <footer className="bg-dark text-white text-center py-2">
        <div className="container">
          <p className="mb-0">
            © 2025 Sociedad Pathfinder de Madrid. Todos los derechos reservados.
          </p>
          <p>
            <a href="mailto:contacto@sociedadpathfinder.es" className="text-white">Contacto</a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
