import { useEffect } from "react";

const Landing = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//pro-pubtags.addoor.net/lib/contags/contags.js";
    // script.src = "//pubtags.addoor.net/lib/contags/contags.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="landing-container">
      <header className="bg-dark text-white text-center py-5">
        <div className="container">
          <h1 className="display-4 anton-regular">Testing Page</h1>
          <p className="lead display fs-2 germania-one-regular">Explorar, reportar y cooperar</p>
        </div>
      </header>
      <main className="landing-main">
        <section className="w-25">
          <div className="addoor-widget" id="addoor-widget-2582"></div>
          <div className="saboxplugin-wrap"></div>
        </section>
      </main>
    </div>
  );
};

export default Landing;
