import React, { useState, useRef, useEffect } from 'react';
import './Lena.css'; // Archivo CSS para estilos personalizados

const Landing = ({ hideNavbar }) => {
  const [authorized, setAuthorized] = useState(false); // Controla el acceso
  const [resultado, setResultado] = useState(null);
  const [mensajes, setMensajes] = useState([]);
  const resultsRef = useRef(null); // Referencia para el contenedor de resultados

  const iniciarSimulacion = () => {
    setMensajes([]); // Reiniciar mensajes
    intentarSacarCurso(0);
  };

  const intentarSacarCurso = (resultadoExamen) => {
    if (resultadoExamen !== 7) {
      const nuevoResultado = Math.floor(Math.random() * 10);
      setMensajes((prevMensajes) => [
        ...prevMensajes,
        `Resultado ${nuevoResultado}. ${nuevoResultado < 7 ? '💔 No es un fallo, es la oportunidad de intentarlo otra vez y mejorar' : '🌟 ¡Lo lograste!'}`,
      ]);

      if (nuevoResultado < 7) {
        setTimeout(() => intentarSacarCurso(nuevoResultado), 1000);
      } else {
        setResultado('¡Eres increíble, Leni! Este éxito es una prueba de tu determinación. 🌹');
      }
    } else {
      setResultado('¡Perfecto! Lo has sacado a la primera porque eres la hostia!.');
    }
  };

  // Scroll automático al final de los resultados
  useEffect(() => {
    if (resultsRef.current) {
      resultsRef.current.scrollTop = resultsRef.current.scrollHeight;
    }
  }, [mensajes]);

  // Solicitar contraseña
  const requestAccess = () => {
    const password = prompt('Por favor, introduce la contraseña para acceder:');
    if (password === 'cocoquito') {
      setAuthorized(true); // Acceso permitido
    } else {
      alert('Contraseña incorrecta. Inténtalo de nuevo.');
    }
  };

  // Mostrar la solicitud de contraseña si no está autorizado
  if (!authorized) {
    requestAccess();
    return null;
  }

  return (
    
    <div className="landing-container">
      <header className="landing-header">
        <h2 className="milani-title pt-4 darkbg"><strong>No importa cuántas veces falles, importa que siempre vuelvas a intentarlo</strong></h2>
      </header>
      <main className="landing-main">
        <section className="motivation">
          <div className="motivation-content">
            <img
              src="https://pathfinderwiki.com/w/images/thumb/1/1d/Milani_religious_symbol.webp/427px-Milani_religious_symbol.webp?20240606231407"
              alt="MilaniSymbol"
              className="image"
            />
            <blockquote className="quote">
             "El éxito es la suma de pequeños intentos, incluso después de cada caída"
            </blockquote>
          </div>
        </section>
        <section className="simulation">
          <div className="simulation-content">
            <p className="simulation-text">
              <i>
                Como sé que para ti cualquier resultado no es suficiente, hace falta al menos un 7 para darlo "por bueno" y
                además así ver que incluso con varios fallos, al final se puede conseguir.
              </i>
            </p>
          </div>
          <button onClick={iniciarSimulacion} className="btn btn-dark">
            Inicia la simulación
          </button>
          <div className="simulation-results" ref={resultsRef}>
            {mensajes.map((mensaje, index) => (
              <p key={index}>{mensaje}</p>
            ))}
            {resultado && <p className="resultado-final">{resultado}</p>}
          </div>
        </section>
      </main>
      <hr className="m-4" />
      <section className="milani-info row">
        <div className="milani-block">
          <div className="milani-content col-10 ms-auto me-auto">
            <h2 className="milani-title pt-4"><strong>Inspiración en Milani</strong></h2>
            <p>
              Me he inspirado en <strong>Milani</strong>, conocida como <i>La Everbloom</i>, una deidad de <i>Pathfinder</i> que simboliza la lucha
              contra la opresión, la esperanza y en especial <strong>fuerza interior</strong> que de esa tu me has demostrado que tienes un montón.
              Solo espero que "no odies las rosas" porque es su símbolo sagrado. Milani protege a aquellos que enfrentan injusticias y luchan por un
              mundo mejor, especialmente a quienes han sufrido pero nunca han perdido la esperanza.
            </p>
            <p>
              He elegido a Milani porque su esencia resuena contigo y su nombre un poco también, Leni. Como tu misma me dijiste seguramente no
              conozco ni el 10% de todo por lo que has pasado pero eso me demuestra tu determinación y tu fuerza no solo para superarlo, sino que
              también pese a las dificultades eres una buena persona que quiere ayudar a los demás. Tu historia de superación me recuerda al símbolo
              de Milani, la rosa que crece en suelo sangriento (la que hay más arriba donde el botón o en el escudo de la imagen de fondo); algo
              bonito que crece y florece incluso en los momentos más difíciles.
            </p>
            <p>
              Obviamente la vida va por rachas y en tu caso, lamentablemente han sido más malas que buenas con diferencia, pero también las ha habido
              buenas y eso quiere decir que puede haber más y su duración puede ser más o menos, pero las habrá.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;
