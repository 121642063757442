// src/components/BulkAddPlaythroughForm.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Alert,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { Save as SaveIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";

const BulkAddPlaythroughForm = () => {
  const { characterId } = useParams();
  const navigate = useNavigate();
  
  const [scenarios, setScenarios] = useState([]);
  const [filteredScenarios, setFilteredScenarios] = useState([]);
  
  const [filters, setFilters] = useState({
    seasons: [], // Array para múltiples temporadas
    name: "",
  });
  
  const [selectedScenarios, setSelectedScenarios] = useState([]);
  
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [submissionError, setSubmissionError] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState("");

  const [uniqueSeasons, setUniqueSeasons] = useState([]); // Temporadas únicas

  useEffect(() => {
    const fetchScenarios = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/characters/${characterId}/scenarios/bulk-add-data/`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
        });
        setScenarios(response.data);
        setFilteredScenarios(response.data);

        // Obtener temporadas únicas y ordenarlas
        const seasons = [...new Set(response.data
          .filter((scenario) => scenario.type === "escenario" || scenario.type === "quest1" || scenario.type === "quest2")
          .map((scenario) => scenario.season))].sort((a, b) => a - b);
        setUniqueSeasons(seasons);

        // Prellenar selectedScenarios con las crónicas existentes
        const existingPlaythroughs = response.data
          .filter(scenario => scenario.playthrough)
          .map(scenario => ({
            scenario: scenario.id,
            is_player: scenario.playthrough.is_player,
            is_gm: scenario.playthrough.is_gm,
            experience_gained: scenario.playthrough.experience_gained,
            notes: scenario.playthrough.notes || "",
          }));
        setSelectedScenarios(existingPlaythroughs);
      } catch (err) {
        setError("Error al cargar los escenarios.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchScenarios();
  }, [characterId]);

  const handleFilterChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "season") {
      // Manejar la selección de temporadas
      setFilters((prev) => {
        const newSeasons = checked
          ? [...prev.seasons, value]
          : prev.seasons.filter((season) => season !== value);
        return { ...prev, seasons: newSeasons };
      });
    } else {
      // Manejar otros filtros (como nombre)
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    let filtered = scenarios;

    // Filtrar por temporadas seleccionadas
    if (filters.seasons.length > 0) {
      filtered = filtered.filter((scenario) => filters.seasons.includes(scenario.season.toString()));
    }

    // Filtrar por nombre
    if (filters.name.trim() !== "") {
      filtered = filtered.filter((scenario) =>
        scenario.name.toLowerCase().includes(filters.name.toLowerCase())
      );
    }

    setFilteredScenarios(filtered);
  }, [filters, scenarios]);

  const handleScenarioChange = (scenarioId, field, value) => {
    setSelectedScenarios((prev) => {
      const existing = prev.find((s) => s.scenario === scenarioId);
      if (existing) {
        return prev.map((s) =>
          s.scenario === scenarioId ? { ...s, [field]: value } : s
        );
      } else {
        // Obtener el tipo del escenario para asignar XP por defecto
        const scenarioInfo = scenarios.find((s) => s.id === scenarioId);
        const defaultXP = scenarioInfo ? getDefaultXP(scenarioInfo.type) : 0;
        return [...prev, { scenario: scenarioId, [field]: value, experience_gained: defaultXP, notes: "" }];
      }
    });
  };

  const handleCheckboxChange = (scenarioId, field, checked) => {
    handleScenarioChange(scenarioId, field, checked);
  };

  const handleInputChange = (scenarioId, field, value) => {
    handleScenarioChange(scenarioId, field, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmissionError("");
    setSubmissionSuccess("");

    if (selectedScenarios.length === 0) {
      setSubmissionError("No has seleccionado ningún escenario para reportar.");
      return;
    }

    // Prepara los datos sin el campo 'id'
    const payload = selectedScenarios.map(scenario => ({
      scenario: scenario.scenario,
      is_player: scenario.is_player || false,
      is_gm: scenario.is_gm || false,
      experience_gained: scenario.experience_gained || 0,
      notes: scenario.notes || ""
    }));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/characters/${characterId}/scenarios/bulk-add/`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
        }
      );
      setSubmissionSuccess("Crónicas creadas/actualizadas con éxito.");
      navigate(`/characters/${characterId}`);
    } catch (err) {
      setSubmissionError("Error al guardar las crónicas. Inténtalo de nuevo más tarde.");
      console.error(err);
    }
  };

  // función para obtener XP por defecto basado en el tipo de escenario
  const getDefaultXP = (type) => {
    const mapping = {
      "escenario": 4,
      "quest1": 1,
      "quest2": 2,
      "oneshot": 4,
      "modulo": 12,
      "senda": 12
    };
    return mapping[type] || 0; // Retorna 0 si el tipo no está en el mapeo
  };

  const getScenarioData = (scenarioId) => {
    const scenario = selectedScenarios.find((s) => s.scenario === scenarioId);
    if (scenario) {
      return scenario;
    }
    // Obtener la información del escenario para determinar el tipo
    const scenarioInfo = scenarios.find((s) => s.id === scenarioId);
    const defaultXP = scenarioInfo ? getDefaultXP(scenarioInfo.type) : 0;
    return { is_player: false, is_gm: false, experience_gained: defaultXP, notes: "" };
  };
  

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Typography variant="h6">Cargando escenarios...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" className="mt-3">
      <Paper className="p-4 shadow-sm" style={{ border: "1px solid #dadada" }}>
        <Typography variant="h4" className="text-center mb-4">
          Reporte Masivo de Crónicas
        </Typography>

        {error && (
          <Alert severity="error" className="mb-4">
            {error}
          </Alert>
        )}

        {submissionError && (
          <Alert severity="error" className="mb-4">
            {submissionError}
          </Alert>
        )}

        {submissionSuccess && (
          <Alert severity="success" className="mb-4">
            {submissionSuccess}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          {/* Filtros */}
          <Grid container spacing={2} className="mb-4">
            {/* Filtro por Temporada: Checkboxes */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom>
                Filtrar por Temporada
              </Typography>
              {uniqueSeasons.map((season) => (
                <FormControlLabel
                  key={season}
                  control={
                    <Checkbox
                      name="season"
                      value={season}
                      checked={filters.seasons.includes(season.toString())}
                      onChange={handleFilterChange}
                      color="primary"
                    />
                  }
                  label={`Temporada ${season}`}
                />
              ))}
            </Grid>
            
            {/* Filtro por Nombre */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Filtrar por Nombre"
                name="name"
                value={filters.name}
                onChange={handleFilterChange}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Lista de Escenarios en Tabla */}
          <TableContainer component={Paper} style={{ maxHeight: 500, overflow: "auto" }}>
            <Table stickyHeader size="small" aria-label="Escenarios">
              <TableHead>
                <TableRow>
                  {/* Cabecera vacía ya que cada escenario tiene dos líneas */}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredScenarios.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={1} align="center">
                      <Typography>No hay escenarios que coincidan con los filtros.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredScenarios.map((scenario) => {
                    const scenarioData = getScenarioData(scenario.id);
                    return (
                      <TableRow key={scenario.id}>
                        <TableCell>
                          <Box display="flex" flexDirection="column">
                            {/* Primera Línea: Nombre y Repetible */}
                            <Box display="flex" alignItems="center">
                              <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                                #{scenario.season}
                                {scenario.number ? `-${scenario.number}` : ""}: {scenario.name} {scenario.evergreen === "True" ? "(Repetible)" : ""}
                              </Typography>
                            </Box>
                            
                            {/* Segunda Línea: Checkboxes y XP */}
                            <Box display="flex" alignItems="center" mt={1}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={scenarioData.is_player}
                                    onChange={(e) =>
                                      handleCheckboxChange(scenario.id, "is_player", e.target.checked)
                                    }
                                    color="primary"
                                  />
                                }
                                label="Jugador"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={scenarioData.is_gm}
                                    onChange={(e) =>
                                      handleCheckboxChange(scenario.id, "is_gm", e.target.checked)
                                    }
                                    color="primary"
                                  />
                                }
                                label="GM"
                              />
                              <TextField
                                type="number"
                                label="XP"
                                value={scenarioData.experience_gained}
                                onChange={(e) =>
                                  handleInputChange(scenario.id, "experience_gained", e.target.value)
                                }
                                inputProps={{ min: 0 }}
                                size="small"
                                style={{ width: '80px', marginLeft: '16px' }}
                              />
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Botones */}
          <Box display="flex" justifyContent="space-between" mt={4}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(`/characters/${characterId}`)}
            >
              Atrás
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              Guardar
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default BulkAddPlaythroughForm;
