import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Auth.css";
import axios from "axios";

function Login() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Hook para capturar parámetros de la URL

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("access_token");
    const refreshToken = params.get("refresh_token");

    if (accessToken && refreshToken) {
      // Guardar los tokens en el localStorage
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);

      // Verificar el token automáticamente con el backend
      loginWithToken(accessToken);
    }

    const timer = setTimeout(() => {
      setShowForm(true);
    }, 500);

    return () => clearTimeout(timer);
  }, [location.search, navigate]); // Dependencias: location.search y navigate

  // Función para hacer login automáticamente usando el access_token
  const loginWithToken = async (accessToken) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-profile/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`  // Enviar el token en las cabeceras
        }
      });

      console.log("Respuesta del servidor:", response);

      // Si la solicitud es exitosa, el token es válido, por lo que autenticamos al usuario
      if (response.status === 200) {
        // El token es válido, redirigir al dashboard
        navigate("/dashboard");
      }
    } catch (error) {
      // Si el token no es válido o ha expirado
      console.error("Error autenticando con el token:", error);
      setError("Error de autenticación, por favor inicie sesión nuevamente.");
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    fetch(`${process.env.REACT_APP_API_URL}/login/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) throw new Error("Credenciales incorrectas.");
        return response.json();
      })
      .then((data) => {
        // Guardar el token en el localStorage
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("refresh_token", data.refresh_token);
        
        // Debug
        console.log("Tokens almacenados en localStorage:", localStorage.getItem("access_token"), localStorage.getItem("refresh_token"));

        // Redirigir al usuario a la URL previa o al dashboard
        const redirectTo = new URLSearchParams(location.search).get("redirect");
        navigate(redirectTo || "/dashboard"); // Si no existe redirect, redirige al dashboard
      })
      .catch(() => setError("Usuario o contraseña incorrectos."));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-11 m-auto mt-3">
          <h2 className="text-center mb-2 mt-0">Inicia Sesión</h2>
        </div>
        <div className="auth-container col-11 m-auto mt-1">
          <div className={`card-container ${showForm ? "flipped" : ""}`}>
            {/* Imagen inicial */}
            <div className="card-side front"></div>

            {/* Formulario */}
            <div className="card-side back">
              <form onSubmit={handleSubmit} className="auth-form">
                {error && <div className="alert alert-danger">{error}</div>}
                {success && <div className="alert alert-success">{success}</div>}

                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Nombre de usuario
                  </label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    className="form-control"
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="password" className="form-label">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button type="submit" className="btn btn-dark w-100">
                  Iniciar Sesión
                </button>
              </form>
              <p className="pt-5 under-text">
                ¿Has olvidado tu contraseña? <a href="/password-reset">Recupérala aquí</a>.
              </p>
            </div>
          </div>
        </div>
        <div className="col-11 m-auto mt-3"></div>
      </div>
    </div>
  );
}

export default Login;
