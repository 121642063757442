import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import axios from "axios"; // Importamos axios para las solicitudes HTTP
import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * Componente principal que muestra todos los eventos disponibles.
 * Obtiene los eventos desde la API y los muestra en una lista.
 */
function App() {
  // Estado para almacenar los eventos
  const [eventos, setEventos] = useState([]);
  const [error, setError] = useState(null); // Estado para manejar errores

  /**
   * Hook useEffect que se ejecuta cuando el componente se monta.
   * Obtiene la lista de eventos desde la API.
   */
  useEffect(() => {
    const fetchEventos = async () => {
      try {
        // Realizamos la solicitud GET a la API para obtener los eventos
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/eventos/`);
        setEventos(response.data); // Almacenamos los eventos obtenidos en el estado
      } catch (err) {
        console.error("Error al obtener los eventos:", err);
        setError("No se pudieron cargar los eventos. Por favor, inténtalo más tarde.");
      }
    };

    fetchEventos(); // Llamamos a la función para obtener los eventos desde la API
  }, []); // El array vacío asegura que solo se ejecuta una vez al montar el componente

  return (
    <div>
      {/* Sección de Eventos */}
      <section id="events" className="pt-2">
        <div className="container">
          <Fade direction="up" triggerOnce>
            <h2 className="text-center mb-4 mt-3">Nuestros eventos</h2>

            {/* Verificamos si hay eventos disponibles */}
            {error ? (
              <p className="text-center">{error}</p>
            ) : eventos.length > 0 ? (
              <div className="row">
                {/* Mapeamos los eventos y los mostramos en tarjetas */}
                {eventos.map((evento) => (
                  <div key={evento.id} className="col-md-6">
                    <Link to={`/event/${evento.id}`} className="text-decoration-none">
                      <div className="card mb-3 d-flex flex-row align-items-stretch">
                        <div className="row g-0 align-items-center">
                          {/* Columna de la imagen */}
                          <div className="col-4 col-md-4 d-none d-md-block">
                            {/* Imagen móvil */}
                            <img
                              src={evento.imagen}
                              className="img-fluid rounded-start h-100"
                              style={{ maxHeight: "500px" }}
                              alt={evento.titulo}
                            />
                          </div>
                          <div className="col-4 d-md-none">
                            {/* Imagen escritorio */}
                            <img
                              src={evento.imagen}
                              className="img-fluid rounded-start w-100 h-100 object-fit-cover"
                              alt={evento.titulo}
                            />
                          </div>
                          {/* Columna de los datos */}
                          <div className="col-8">
                            <div className="card-body">
                              <h5 className="card-title">
                                <strong>{evento.titulo}</strong>
                              </h5>
                              <hr className="mb-1 mt-1" />
                              <p className="card-text mb-0" style={{ fontSize: "14px" }}>
                                <strong>Fecha:</strong> {evento.fecha}
                              </p>
                              <p className="card-text mb-0" style={{ fontSize: "14px" }}>
                                <strong>Sistemas:</strong> {evento.sistemas?.join(", ")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center">No hay eventos disponibles en este momento.</p>
            )}
          </Fade>
        </div>
      </section>
    </div>
  );
}

export default App;
