import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"; 
import logo from "../assets/images/pfs_icon.png";
import "./Navbar.css"; // CSS opcional para mejorar estilos

function Navbar({ hideNavbar }) { 
  console.log("hide: " + hideNavbar);  
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  if (hideNavbar) return null;
  // Comprobar si el usuario está autenticado
  const isAuthenticated = !!localStorage.getItem("access_token");

   // Función para manejar el logout
  const handleLogout = () => {
    localStorage.removeItem("access_token"); // Eliminar token del almacenamiento local
    localStorage.removeItem("refresh_token"); // Opcional: eliminar refresh token
    navigate("/login"); // Redirigir al login
    // setShowMenu(!showMenu) // Ocultamos el menu lateral
  };
  
  const toggleMenu = () => setShowMenu(!showMenu);
  
  return (
    <>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          {/* Logo */}
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <img src={logo} alt="Logo" style={{ height: "40px" }} className="me-2" />
            <span className="fw-bold">Pathfinder Madrid</span>
          </Link>

          {/* Botón hamburguesa (visible solo en resoluciones pequeñas) */}
          <button
            className="btn btn-dark d-block d-md-none"
            type="button"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Menú principal (visible en resoluciones grandes) */}
          <div className="collapse navbar-collapse d-none d-lg-flex" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">Inicio</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/events/">Eventos</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/organizedplay">Juego Organizado</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">Contacto</Link>
              </li>
              {/* Mostrar enlaces según autenticación */}
              {!isAuthenticated ? (
              <>
              <li className="nav-item">
                <Link className="nav-link" to="/register">Registro</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/login">Iniciar Sesión</Link>
              </li> 
              </>
              ) : (
              <>
              <li className="nav-item">
                  <Link className="nav-link" to="/dashboard"> Panel de Usuario</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/login" onClick={handleLogout}>Cerrar Sesión</Link>
              </li>
              </>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {/* Offcanvas Menu (visible solo en resoluciones pequeñas) */}
      <div className={`offcanvas offcanvas-end ${showMenu ? "show" : ""}`} style={{ visibility: showMenu ? "visible" : "hidden" }}>
        <div className="offcanvas-header">
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <img src={logo} alt="Logo" style={{ height: "40px" }} className="me-2" />
            <span className="fw-bold">Pathfinder Madrid</span>
          </Link>
          <button type="button" className="btn-close btn-close-white me-1" onClick={toggleMenu}></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={toggleMenu}>Inicio</Link>
            </li>            
            <li className="nav-item">
              <Link className="nav-link" to="/events/" onClick={toggleMenu}>Eventos</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/organizedplay/" onClick={toggleMenu}>Juego Organizado</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={toggleMenu}>Contacto</Link>
            </li>
            {!isAuthenticated ? (
            <>
            <li className="nav-item">
              <Link className="nav-link" to="/register" onClick={toggleMenu}>Registro</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/login" onClick={toggleMenu}>Iniciar Sesión</Link>
            </li>
            </>
            ) : (
            <>
            <li className="nav-item">
              <Link className="nav-link" to="/dashboard" onClick={toggleMenu}>Panel de Usuario</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/login" onClick={handleLogout}>Cerrar Sesión</Link>
            </li>
            </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
