import React, { useEffect } from 'react';

const StarfinderSection = () => {
  useEffect(() => {
    // Inicializar el comportamiento de los acordeones
    const accordionButtons = document.querySelectorAll('.accordion-button');
    accordionButtons.forEach(button => {
      if (button.classList.contains('collapsed')) {
        button.style.backgroundColor = '#ffffff'; // Estilo por defecto (cerrado)
        button.style.color = '#000'; // Color de texto por defecto
      } else {
        button.style.backgroundColor = '#353535'; // Estilo cuando está abierto
        button.style.color = '#fff'; // Color de texto cuando está abierto
      }
    });
  }, []);

  return (
    <section className="pathfinder-section">
   
{/* 
      <div className="accordion mt-3 mb-3">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pf2factions">
              <span className='fs-5 fw-bold'>Facciones de Pathfinder 2e</span>
            </button>
          </h2>
          <div id="pf2factions" className="accordion-collapse collapse">
            <div className="accordion-body">
            </div>
          </div>
        </div>
      </div> */}

      <div className="accordion mt-3 mb-3">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pf2links">
              <span className='fs-5 fw-bold'>Enlaces de interés SFS1e</span>
            </button>
          </h2>
          <div id="pf2links" className="accordion-collapse collapse">
            <div className="accordion-body">
              <p className='mb-0'>Los siguientes enlaces pueden ser útiles en el Juego Organizado de Pathfinder 2e</p>
              <ul>
                <li className='mb-0 mt-0'><a href="https://lorespire.paizo.com/tiki-index.php?page=pfs2guide._.Full-Guide">Guía de Juego Organizado para Pathfinder 2e</a></li>
                <li className='mb-0 mt-0'><a href="https://paizo.com/pathfindersociety/characteroptions">Opciones de Personajes</a></li>
                <li className='mb-0 mt-0'><a href="https://paizo.com/pathfindersociety/faq">FAQs de Pathfinder</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StarfinderSection;
