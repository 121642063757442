import React, { useState } from "react";
import { Container, Card, Typography, TextField, Button, Alert } from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";

function Contact() {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!formData.name || !formData.email || !formData.message) {
      setError("Por favor, rellena todos los campos.");
      setSuccess("");
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/contact/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.error || "Error al enviar el mensaje");
      }
  
      setSuccess("¡Gracias por tu mensaje! Nos pondremos en contacto contigo pronto.");
      setError("");
      setFormData({ name: "", email: "", message: "" });
  
    } catch (err) {
      setError(err.message);
      setSuccess("");
    }
  };
  
  return (
    <Container maxWidth="sm" className="mt-5">
      <Card className="p-4 shadow-sm" style={{ border: "1px solid #dadada" }}>
        <Typography variant="h4" className="text-center mb-3">
          Contáctanos
        </Typography>
        <Typography variant="body1" className="text-center mb-4">
          ¿Tienes alguna pregunta o necesitas ayuda? ¡Envíanos un mensaje!
        </Typography>

        {error && (
          <Alert severity="error" className="mb-3">
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" className="mb-3">
            {success}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            label="Nombre"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Correo electrónico"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Mensaje"
            name="message"
            value={formData.message}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
            required
          />
          <div className="d-flex justify-content-center mt-4">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              style={{ fontWeight: "bold" }}
            >
              Enviar
            </Button>
          </div>
        </form>
      </Card>
    </Container>
  );
}

export default Contact;
