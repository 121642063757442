import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

// Nuevo modal para seleccionar el rol
const RoleSelectionModal = ({ show, onHide, onSubmit, partida }) => {
  const [selectedRole, setSelectedRole] = useState("Jugador");

  const handleSubmit = () => {
    onSubmit(partida.id, selectedRole);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar Rol</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Cómo deseas inscribirte en {partida.nombre}?</p>
        <div>
          <Button variant={selectedRole === "Jugador" ? "primary" : "secondary"} onClick={() => setSelectedRole("Jugador")} disabled={partida.jugadores.length >= 8}>
            Jugador
          </Button>
          {" "}
          <Button variant={selectedRole === "GM" ? "primary" : "secondary"} onClick={() => setSelectedRole("GM")} disabled={partida.gm}>
            Gamemaster
          </Button>
        </div>
      </Modal.Body>
      <div className="ms-4" style={{fontSize: "13px"}}>
      <p className="mb-0">Recuerda el lema ético de la Sociedad Pathfinder:</p>
      <ul>
        <li>No toques lo que no es tuyo (incluida personas)</li>
        <li>Respeta a los demás</li>
        <li>El master siempre tiene razón y Paizo también</li>
        <li>Se puntual o Drandle Dreng te comera</li>
      </ul>
      </div>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="dark" onClick={handleSubmit}>
          Confirmar
        </Button>
      </Modal.Footer>
      

    </Modal>
  );
};

export default RoleSelectionModal;
