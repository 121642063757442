import React, { useState } from 'react';
import { motion } from 'framer-motion'; // Importar framer-motion
import PathfinderSection from '../components/OrganizedplayPathfinderResources';
import StarfinderSection from '../components/OrganizedplayStarfinderResources';
import CommonResources from '../components/OrganizedplayCommonResources';
import logopfs from "../assets/images/pfs_icon2.png";
import logosfs from "../assets/images/sfs_icon.png";
import "./OrganizedPlay.css";

const LandingPage = () => {
  const [selectedSystem, setSelectedSystem] = useState(null);

  // Funciones para manejar el clic en las imágenes
  const handlePathfinderClick = () => setSelectedSystem('pathfinder');
  const handleStarfinderClick = () => setSelectedSystem('starfinder');

  return (
    <div>
      <header className="bg-dark text-white text-center py-1">
        <div className="container">
          <h1 className="display-4 anton-regular">Bienvenidos al Juego Organizado</h1>
          <p className="lead display fs-2 germania-one-regular">Explora las opciones para <br></br>Pathfinder y Starfinder</p>
        </div>
      </header>
      <div className='container'>
        {/* Mostrar elementos comunes */}
        <CommonResources />
        <hr />
      </div>
      
      {/* Botones de selección de sistema */}
      <div className="system-selection text-center mt-2 row  mb-0">
        <p className='fw-bold mb-0'>Elige el sistema para ver más información</p>
        <div className='col-6 pfs-back border-end'>
          <img
            src={logopfs}
            alt="Pathfinder"
            onClick={handlePathfinderClick}
            className="system-image"
          />
          <span className='germania-one-regular'>Pathfinder Society</span>
        </div>
        <div className='col-6 sfs-back'>
            <img
              src={logosfs}
              alt="Starfinder"
              onClick={handleStarfinderClick}
              className="system-image"
            />
            <span className='germania-one-regular'>Starfinder Society</span>
        </div>        
      </div>

      <div className='container'>
        <hr />
        {/* Mostrar el contenido de Pathfinder o Starfinder con animación */}
        {selectedSystem === 'pathfinder' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            <PathfinderSection />
          </motion.div>
        )}
        {selectedSystem === 'starfinder' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            <StarfinderSection />
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
