// UserChronicles.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Alert,
  TextField,
} from "@mui/material";

const UserChronicles = () => {
  const [chronicles, setChronicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchText, setSearchText] = useState(""); // Estado para el filtro de búsqueda

  useEffect(() => {
    const fetchChronicles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/playthroughs/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        setChronicles(response.data);
      } catch (err) {
        setError("Error al cargar las crónicas.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchChronicles();
  }, []);

  // Filtrar las crónicas basándose en el nombre del escenario
  const filteredChronicles = chronicles.filter((chronicle) =>
    chronicle.scenario_name.toLowerCase().includes(searchText.toLowerCase())
  );

  if (loading) {
    return (
      <Container style={{ textAlign: "center", marginTop: "2rem" }}>
        <CircularProgress />
        <Typography variant="body1">Cargando crónicas...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container style={{ marginTop: "2rem" }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container style={{ marginTop: "2rem" }}>
      <TextField
        label="Buscar partida"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Ingresa el nombre de la partida..."
      />
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Escenario</strong></TableCell>
                <TableCell><strong>Rol</strong></TableCell>
                <TableCell><strong>Personaje</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredChronicles.length > 0 ? (
                filteredChronicles.map((chronicle) => (
                  <TableRow key={chronicle.id}>
                    <TableCell>
                      #{chronicle.scenario_season}-{chronicle.scenario_number}: {chronicle.scenario_name}
                    </TableCell>
                    <TableCell>
                      {chronicle.is_player ? "Jugador" : "GM"}
                    </TableCell>
                    <TableCell>{chronicle.character_name || "Sin PJ"}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No se encontraron partidas.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default UserChronicles;
