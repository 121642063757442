import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Dashboard.css";
import CharacterTable from "../components/CharacterTable";
import UserChronicles from "../components/UserChronicles";

function Dashboard() {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    fullName: "",
  });

  const [characters, setCharacters] = useState([]);
  const [loadingCharacters, setLoadingCharacters] = useState(true);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
    fetchCharacters();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-profile/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setFormData({
        username: response.data.username,
        email: response.data.email,
        fullName: response.data.full_name || "",
      });
    } catch (err) {
      handleAuthError(err);
    }
  };

  const fetchCharacters = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/characters/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setCharacters(response.data);
      setLoadingCharacters(false);
    } catch (err) {
      console.error("Error al obtener personajes:", err);
      setLoadingCharacters(false);
    }
  };

  const handleAuthError = async (err) => {
    if (err.response && err.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      if (newAccessToken) {
        await fetchUserData();
      }
    } else {
      console.error(err);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      navigate("/login");
    }
  };

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refresh_token");
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/token/refresh/`, {
        refresh: refreshToken,
      });
      const newAccessToken = response.data.access;
      localStorage.setItem("access_token", newAccessToken);
      return newAccessToken;
    } catch (error) {
      console.error("Error al refrescar el token:", error);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      navigate("/login");
      return null;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/user-profile/`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setSuccess("Datos actualizados con éxito.");
    } catch (err) {
      console.error(err);
      setError("Hubo un problema al actualizar los datos.");
    }
  };

  return (
    <div className="container my-2">
      {/* Formulario de usuario */}
      <div className="section-box">
        <h2 className="text-center">Datos del usuario</h2>
        <form onSubmit={handleSubmit} className="dashboard-form mt-4">
          {error && <div className="alert alert-danger">{error}</div>}
          {success && <div className="alert alert-success">{success}</div>}

          <div className="mb-3">
            <label htmlFor="username" className="form-label">
              Nombre de usuario
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="form-control"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Correo electrónico
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="fullName" className="form-label">
              Nombre completo (visible solo para organizadores)
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              className="form-control"
              value={formData.fullName}
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="btn btn-dark w-100">
            Guardar cambios
          </button>
        </form>
      </div>

      {/* Lista de Personajes */}
      <div className="section-box mt-3">
        <h2 className="text-center">Mis Personajes</h2>
        <p>Los personajes en la web solo sirven para anotar las partidas jugadas/dirigidas y tener un seguimiento a mano en el móvil sin necesidad de mirar todas las crónicas en papel. <b>Pero las crónicas fisicas y las partidas reportadas</b> en paizo siguen siendo los medios oficiales que demuestran que has jugado o dirigido una partida.</p>
        <CharacterTable characters={characters} loading={loadingCharacters} />
      </div>
      <div className="section-box mt-3">
        <h2 className="text-center">Mis Crónicas</h2>
        <p>Todas las crónicas que tienes registradas en la web, útil para cuándo el GM dice "¿Alguien ha jugado la partida...?"</p>
        <UserChronicles characters={characters} loading={loadingCharacters} />
      </div>
    </div>
  );
}

export default Dashboard;