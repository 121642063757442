import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Card,
  Button,
  Typography,
  TextField,
  Alert,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
  Box,
} from "@mui/material";
import {
  Save as SaveIcon,
  Delete as DeleteIcon,
  AutoStories as AutoStoriesIcon,
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
} from "@mui/icons-material";

const CharacterDetail = () => {
  const { characterId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    paizo_id: "",
  });
  const [character, setCharacter] = useState(null);
  const [playthroughs, setPlaythroughs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null); // Estado para controlar filas expandidas

  // Función para obtener los datos del personaje y las crónicas
  const fetchCharacterData = async () => {
    setLoading(true);
    try {
      const characterResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/characters/${characterId}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setCharacter(characterResponse.data);
      setFormData({
        name: characterResponse.data.name,
        paizo_id: characterResponse.data.paizo_id || "",
      });

      const playthroughsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/characters/${characterId}/scenarios/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setPlaythroughs(playthroughsResponse.data);
    } catch (err) {
      setError("Error al cargar los datos. Inténtalo de nuevo más tarde.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCharacterData();
  }, [characterId]);

  const handleRowClick = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/characters/${characterId}/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      // Opcional: mostrar una alerta de éxito
      // alert("Personaje actualizado con éxito.");
      // Refrescar los datos del personaje
      await fetchCharacterData();
    } catch (err) {
      setError("Error al guardar los cambios. Inténtalo de nuevo más tarde.");
      console.error(err);
    } finally {
      setSaving(false);
    }
  };

  const handleDeletePlaythrough = async (playthroughId) => {
    // Confirma la eliminación si lo deseas
    // if (!window.confirm("¿Estás seguro de que quieres eliminar esta crónica?")) return;

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/characters/${characterId}/scenarios/${playthroughId}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      // Después de eliminar, refrescamos los datos del personaje y las crónicas
      await fetchCharacterData();
      // Opcional: mostrar una alerta de éxito
      // alert("Crónica eliminada con éxito.");
    } catch (err) {
      alert("Error al eliminar la crónica. Inténtalo de nuevo más tarde.");
      console.error(err);
    }
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <p>Cargando datos del personaje...</p>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className="mt-3">
      {error && (
        <Alert severity="error" className="mb-4">
          {error}
        </Alert>
      )}

      <Card className="p-4 shadow-sm mb-4" style={{ border: "1px solid #dadada" }}>
        <Typography variant="h4" className="text-center mb-4">
          Editar Personaje
        </Typography>

        <form onSubmit={handleSave}>
          <TextField
            label="Nombre del Personaje"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Paizo ID"
            name="paizo_id"
            value={formData.paizo_id}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nivel"
            value={character.level}
            style={{ width: "40%" }}
            margin="normal"
            disabled
          />
          <TextField
            label="Experiencia"
            value={character.experience}
            style={{ width: "40%", float: "right" }}
            margin="normal"
            disabled
          />
          <div className="d-flex justify-content-between mt-4">
            <Button
              variant="outlined"
              color="warning"
              onClick={() => navigate(`/dashboard/`)}
              startIcon={<ArrowBackIcon />}
            >
              Volver
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              disabled={saving}
            >
              {saving ? "Guardando..." : "Guardar"}
            </Button>
          </div>
        </form>
      </Card>

      <Card className="p-4 shadow-sm" style={{ border: "1px solid #dadada" }}>
        <Typography variant="h5" className="text-center mb-4">
          Crónicas de {character.name}
        </Typography>

        <div className="d-flex justify-content-center mb-1">
          <Button
            component={Link}
            to={`/characters/${characterId}/add-playthrough`}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            Añadir nueva crónica
          </Button>
        </div>
        <div className="d-flex justify-content-center mb-4">
          <Button
            component={Link}
            to={`/characters/${characterId}/scenarios/bulk-add/`}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            Añadir varias crónicas (sin notas)
          </Button>
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Escenario</b></TableCell>
              <TableCell><b>XP</b></TableCell>
              <TableCell><b>Acciones</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {playthroughs.length > 0 ? (
              playthroughs.map((play) => (
                <React.Fragment key={play.id}>
                  <TableRow
                    hover
                    onClick={() => handleRowClick(play.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>
                      #{play.scenario_season}
                      {play.scenario_number ? `-${play.scenario_number}` : ""}: {play.scenario_name}
                    </TableCell>
                    <TableCell>{play.experience_gained}</TableCell>
                    <TableCell>
                      <IconButton
                        style={{ width: 30, height: 30 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/characters/${characterId}/scenarios/${play.id}`);
                        }}
                        color="primary"
                      >
                        <AutoStoriesIcon />
                      </IconButton>
                      <IconButton
                        style={{ width: 30, height: 30 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeletePlaythrough(play.id);
                        }}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} style={{ padding: 0, border: 0 }}>
                      <Collapse in={expandedRow === play.id} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: 2, backgroundColor: "#f9f9f9" }}>
                          <Typography variant="body2">
                            <strong>Rol:</strong> {play.is_player ? "Jugador" : "GM"}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Repetible:</strong> {play.evergreen ? "Sí" : "No"}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Notas:</strong> {play.notes || "Sin notas"}
                          </Typography>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No hay crónicas registradas
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Card>
    </Container>
  );
};

export default CharacterDetail;
