import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Auth.css";

/**
 * Componente que maneja el registro de nuevos usuarios.
 * Realiza una solicitud POST a la API para registrar al usuario y manejar errores de validación.
 */
function Register() {
  // Estados para almacenar los datos del formulario, errores de campo y errores generales
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [fieldErrors, setFieldErrors] = useState({}); // Errores específicos de campo
  const [nonFieldErrors, setNonFieldErrors] = useState([]); // Errores generales (non_field_errors)
  const [success, setSuccess] = useState(""); // Mensaje de éxito
  const [showForm, setShowForm] = useState(false); // Control para la animación del formulario

  // Control de la animación del formulario
  useEffect(() => {
    const timer = setTimeout(() => setShowForm(true), 500);
    return () => clearTimeout(timer);
  }, []);

  /**
   * Maneja los cambios en los campos del formulario.
   * Actualiza los valores y limpia los errores de cada campo.
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFieldErrors({ ...fieldErrors, [name]: "" }); // Limpiar el error del campo actual
  };

  /**
   * Maneja el envío del formulario.
   * Realiza una solicitud POST para registrar al usuario en la API.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFieldErrors({}); // Limpiar errores previos
    setNonFieldErrors([]); // Limpiar errores generales
    setSuccess(""); // Limpiar mensaje de éxito

    try {
      // Enviar solicitud de registro a la API
      const response = await fetch(`${process.env.REACT_APP_API_URL}/register/`, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Accept-Language": "es" },
        body: JSON.stringify(formData),
      });

      // Si la respuesta no es exitosa, lanzar los errores
      if (!response.ok) {
        const data = await response.json();
        throw data; // Lanzar errores para ser manejados en el bloque `catch`
      }

      const data = await response.json();
      setSuccess("Usuario registrado con éxito. ¡Ahora puedes revisa tu email para activar la cuenta!");
      setFormData({ username: "", email: "", password: "", confirm_password: "" });
    } catch (errors) {
      // Manejo de errores, asignando errores de campo y generales
      if (typeof errors === "object") {
        setFieldErrors(errors); // Errores específicos de campo
        if (errors.non_field_errors) {
          setNonFieldErrors(errors.non_field_errors); // Errores generales
        }
      } else {
        setNonFieldErrors(["Hubo un problema al registrar el usuario."]);
      }
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-11 m-auto mt-3">
          <h2 className="text-center mb-2 mt-0">
            Únete a la Sociedad <br /> Pathfinder de Madrid
          </h2>
        </div>
        <div className="auth-container col-11 m-auto mt-1">
          <div className={`card-container ${showForm ? "flipped" : ""}`}>
            {/* Imagen inicial */}
            <div className="card-side front"></div>

            {/* Formulario */}
            <div className="card-side back">
              <form onSubmit={handleSubmit} className="auth-form">
                {/* Mostrar errores generales */}
                {nonFieldErrors.length > 0 && (
                  <div className="alert alert-danger">
                    {nonFieldErrors.map((error, index) => (
                      <div key={index}>{error}</div>
                    ))}
                  </div>
                )}

                {/* Mostrar éxito */}
                {success && <div className="alert alert-success">{success}</div>}

                {/* Campo: Nombre de usuario */}
                <div className="mb-2">
                  <label htmlFor="username" className="form-label">
                    Nombre de usuario
                  </label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    className={`form-control ${fieldErrors.username ? "is-invalid" : ""}`}
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />
                  {fieldErrors.username && <div className="invalid-feedback">{fieldErrors.username[0]}</div>}
                </div>

                {/* Campo: Correo electrónico */}
                <div className="mb-2">
                  <label htmlFor="email" className="form-label">
                    Correo electrónico
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={`form-control ${fieldErrors.email ? "is-invalid" : ""}`}
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  {fieldErrors.email && <div className="invalid-feedback">{fieldErrors.email[0]}</div>}
                </div>

                {/* Campo: Contraseña */}
                <div className="mb-2">
                  <label htmlFor="password" className="form-label">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className={`form-control ${fieldErrors.password ? "is-invalid" : ""}`}
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  {fieldErrors.password && <div className="invalid-feedback">{fieldErrors.password[0]}</div>}
                </div>

                {/* Campo: Confirmar contraseña */}
                <div className="mb-4">
                  <label htmlFor="confirm_password" className="form-label">
                    Confirmar contraseña
                  </label>
                  <input
                    type="password"
                    id="confirm_password"
                    name="confirm_password"
                    className={`form-control ${fieldErrors.confirm_password ? "is-invalid" : ""}`}
                    value={formData.confirm_password}
                    onChange={handleChange}
                    required
                  />
                  {fieldErrors.confirm_password && <div className="invalid-feedback">{fieldErrors.confirm_password[0]}</div>}
                </div>

                <button type="submit" className="btn btn-dark w-100 btn-form">
                  Registrarse
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-11 m-auto mt-3">
          <p className="pt-1 under-text">
            Regístrate para poder apuntarte a los eventos que hacemos mensuales{" "}
            <strong>¡Y jugar partidas de rol gratis!</strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;
