import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./EventDetails.css";

/**
 * Formatea una hora "HH:MM:SS" a "HH:MM".
 * Si horaStr no existe o no contiene ":", la devuelve tal cual.
 */
function formateaHoraSinSegundos(horaStr) {
  if (!horaStr || !horaStr.includes(":")) {
    return horaStr; // Devuelve sin cambios si no es lo esperado
  }
  const [hh, mm] = horaStr.split(":");
  return `${hh}:${mm}`;
}

function EventDetails() {
  const { id } = useParams();
  const [evento, setEvento] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/eventos/${id}/`);
        setEvento(response.data);
      } catch (err) {
        console.error("Error al obtener el evento:", err);
        setError("No se pudo cargar la información del evento.");
      }
    };

    fetchEventData();
  }, [id]);

  if (error) {
    return <div className="text-center my-5">{error}</div>;
  }

  if (!evento) {
    return (
      <div className="text-center my-5">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container my-5">
      {/* Encabezado del evento */}
      <div className="event-header text-white text-center">
        <h1 className="display-4">{evento.titulo}</h1>
        <p className="fs-5 mb-0">Fecha: {evento.formatted_date}</p>
        <p className="fs-5">Sistemas: {evento.sistemas?.join(", ")}</p>
      </div>

      <Fade triggerOnce>
        <hr />
      </Fade>

      {/* Sección con imagen y descripción */}
      <Fade triggerOnce>
        <section className="row g-4 px-3 align-items-stretch">
          <div className="col-md-6 col-12 d-flex">
            <div
              className="section-box w-100 event-image-box"
              style={{
                backgroundImage: `url(${evento.imagen || "https://via.placeholder.com/600"})`,
                backgroundSize: "cover",
                backgroundPosition: "top",
              }}
            ></div>
          </div>
          <div className="col-md-6 col-12 d-flex">
            <div className="section-box w-100">
              <h2 className="text-center">Descripción</h2>
              <div dangerouslySetInnerHTML={{ __html: evento.descripcion }}></div>
            </div>
          </div>
        </section>
      </Fade>

      {/* Tabla con las partidas */}
      <Fade triggerOnce>
        <section className="section-box my-4 mx-3">
          <h2 className="text-center">Partidas</h2>
          <p className="mb-0">
            Puedes apuntarte a las partidas a través de{" "}
            <a href={`/sessions/event/${id}`}>este enlace</a>. Los horarios son:
          </p>

          {/* Mostrar horarios del lugar */}
          <div className="mb-4">
            {evento.lugar?.horarios && evento.lugar.horarios.length > 0 ? (
              <ul>
                {evento.lugar.horarios.map((horario, index) => (
                  <li key={index}>
                    <strong>
                      {horario.dia} ({horario.turno}):
                    </strong>{" "}
                    {formateaHoraSinSegundos(horario.inicio)} a{" "}
                    {formateaHoraSinSegundos(horario.fin)}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No hay horarios disponibles.</p>
            )}
          </div>

          {/* Contenedor para scroll en móviles */}
          <div className="table-responsive">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Dia/Turno</th>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Niveles</th>
                </tr>
              </thead>
              <tbody>
                {evento.partidas?.map((partida) => {
                  const horario = evento.lugar.horarios.find(
                    (h) => `${h.dia} ${h.turno}` === partida.turno
                  );
                  const turnoFormateado = horario
                    ? `${horario.dia} ${horario.turno} (${formateaHoraSinSegundos(horario.inicio)} - ${formateaHoraSinSegundos(horario.fin)})`
                    : partida.turno;

                  const idPartida = `${partida.sistema.toUpperCase()} #${partida.temporada}-${partida.numero_partida}`;

                  return (
                    <tr key={partida.id}>
                      <td>{turnoFormateado}</td>
                      <td>{idPartida}</td>
                      <td>{partida.nombre}</td>
                      <td>{partida.niveles}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </section>
      </Fade>

      {/* Sección con la localización */}
      <Fade triggerOnce>
        <section className="section-box my-4 mx-3">
          <h2 className="text-center">El lugar</h2>
          <div>
            <h4 className="mb-0">{evento.lugar?.nombre}</h4>
            <p className="mt-0 fw-bold">{evento.lugar?.direccion}</p>
            <div dangerouslySetInnerHTML={{ __html: evento.lugar?.descripcion }}></div>
            <div
              className="w-100"
              dangerouslySetInnerHTML={{ __html: evento.lugar?.mapa_html }}
            ></div>
          </div>
          {evento.lugar?.imagen && (
            <div className="mt-3">
              <img
                src={evento.lugar.imagen}
                alt="Localización"
                className="location-image"
              />
            </div>
          )}
        </section>
      </Fade>
    </div>
  );
}

export default EventDetails;
