import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  
  // Configura axios para incluir credenciales
  axios.defaults.withCredentials = true;
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Obtener el token CSRF
    const csrfToken = Cookies.get('csrftoken');
    console.log('Csrftoken:', csrfToken);
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/password-reset/`,
        { email },
        {
          headers: {
            'X-CSRFToken': csrfToken,
          },
        }
      );
      console.log('Response:', response);
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error:', error);
      setMessage(error.response?.data?.error || 'Error al solicitar el restablecimiento de contraseña.');
    }
  };
  
  return (
    <div className="container">
      <div className="row">
        <div className="col-11 m-auto mt-3">
            <h2 className="text-center mb-2 mt-0">Restablecer Contraseña</h2>
        </div>
        <div className="auth-container col-11 m-auto mt-1">  
            <form onSubmit={handleSubmit} className='row'>
              <input
                type="email"
                placeholder="Ingresa tu email"
                value={email}
                className="form-control col-12 col-md-3"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="btn btn-dark w-100 mt-2 col-12 col-md-3">
                    Recuperar contraseña
              </button>
            </form>
        </div>
        {message && <p className='col-12 text-center'>{message}</p>}
      </div>
    </div>
  );
};

export default PasswordResetRequest;