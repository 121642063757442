import React, { useEffect } from 'react';
import imagen1 from "../assets/images/RegistroPaizo.jpg";
import imagen2 from "../assets/images/OrganizedPlay.jpg";
import imagen3 from "../assets/images/OrganizedPlayMobile.jpg";
import imagen4 from "../assets/images/Crear.jpg";

const CommonResources = () => {
  useEffect(() => {
    // Inicializar el comportamiento de los acordeones
    const accordionButtons = document.querySelectorAll('.accordion-button');
    accordionButtons.forEach(button => {
      if (button.classList.contains('collapsed')) {
        button.style.backgroundColor = '#ffffff'; // Estilo por defecto (cerrado)
        button.style.color = '#000'; // Color de texto por defecto
      } else {
        button.style.backgroundColor = '#353535'; // Estilo cuando está abierto
        button.style.color = '#fff'; // Color de texto cuando está abierto
      }
    });
  }, []);

  return (
    <section className="common-resources mt-2">
        
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="common-one">
            <button className="accordion-button btn-outline-dark collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#whatsorganizedplay">
              <span className='fs-5 fw-bold'>¿Qué es el Juego Organizado?</span>
            </button>
          </h2>
          <div id="whatsorganizedplay" className="accordion-collapse collapse">
            <div className="accordion-body">
              <p>El Juego Organizado de Pathfinder Society y Starfinder Society es una experiencia única diseñada por Paizo para ofrecer partidas estructuradas y conectadas en un universo compartido. En este sistema, cada jugador controla un personaje que puede participar en aventuras oficiales, conocidas como scenarios, y acumular progreso y recompensas que se transfieren de una partida a otra, sin importar dónde se juegue.</p>
              <p>Estas partidas están diseñadas para ser autoconclusivas, con una duración aproximada de 4 horas, y siguen las reglas de los sistemas Pathfinder 2e y Starfinder 2e. Además, el Juego Organizado tiene un conjunto adicional de normativas que garantizan la coherencia y la equidad entre jugadores y narradores de todo el mundo.</p>
              <p>El objetivo del Juego Organizado es proporcionar una comunidad global conectada, donde los jugadores puedan unirse a partidas en cualquier lugar y disfrutar de historias emocionantes en un entorno estructurado. Es ideal tanto para principiantes como para jugadores experimentados, ya que ofrece una manera sencilla de disfrutar del rol con reglas claras y universales.</p>
              <p>Si buscas vivir aventuras únicas, conocer nuevos jugadores y formar parte de una comunidad internacional, el Juego Organizado de Pathfinder y Starfinder es para ti. ¡Y lo mejor de todo, participar es completamente gratuito!</p>
            </div>
          </div>
        </div>
      </div>

      <div className="accordion mt-2">
        <div className="accordion-item">
          <h2 className="accordion-header" id="common-two">
            <button className="accordion-button btn-outline-dark collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#registerpaizo">
              <span className='fs-5 fw-bold'>Registro en Paizo & Creación de PJ de Juego Organizado</span>
            </button>
          </h2>
          <div id="registerpaizo" className="accordion-collapse collapse">
            <div className="accordion-body">
            <p>A continuación, te explicamos como registrar un personaje oficial para jugar al juego organizado de la Sociedad Pathfinder.</p>
              <p>1. Primero, tienes que crearte una cuenta oficial de paizo, puedes hacerlo en la <a target="_blank" href="https://paizo.com/paizo/account"> página oficial de paizo</a>. Haciendo clic en el botón "create new account" sigue el proceso de registro como te indica la web.</p>
              <img className='img-tuto' src={imagen1} alt="Registro Paizo" />
              <hr />
              <p>2. El segundo paso, una vez tengas tu cuenta creada y activada (en caso de ser necesario), es hacer login en tu cuenta y acceder a la sección de <strong>"Organized Play"</strong>, puedes acceder desde el menu superior a la derecha, dónde pone <strong>"My account"</strong> como se ve en la siguiente imagen.</p>
              <p>En escritorio:</p>
              <img className='img-tuto' src={imagen2} alt="Organized Play" />
              <p className='mt-4'>En móvil:</p>
              <img className='img-tuto' src={imagen3} alt="Organized Play Mobile" />
              <hr />
              <p>3. El tercer paso, es seleccionar el tipo de personaje de juego organizado quieres crear y registrar Pero antes, fijate en tu número de juego organizado, es el número que pone al lado de <strong>"Your Organized Play ID is"</strong> este número es tu identificador de paizo, todos tus personajes serán ese número seguido de un guión y otro número. Para registrar un personaje, debes elegir la opción adecuada para el sistema que quieres. Ya sea Pathfinder 1e, Pathfinder 1e CORE, Pathfinder 2e, Starfinder 1e o Starfinder 2e</p>
              <img className='img-tuto' src={imagen4} alt="Crear PJ" />
              <hr />
              <p>4. El último paso es rellenar el formulario del personaje, elige el nombre, la facción y rellena algunos de los campos que quieras, selecciona un avatar y dale al botón de <strong>"Submit changes"</strong> y ya has acabado de registrar tu personaje, ahora tu personaje te saldrá en la lista y su número de personaje tendrá un formato como #TuNumeroDePaizo-X siendo X un número ascendente que indicará el si es el primero, segundo, tercero, etc. Dependiendo de la modalidad (Pathfinder 1e, Pathfinder 2e, Starfinder 1e, Starfinder 2e) X tendrá un formato por ejemplo los de Pathfinder 2e son 2001, 2002... Mientras que los de Starfinder 1e son 701, 702...</p>
              <hr />
              <p>Con tu personaje ya registrado, solo necesitas ir a un evento de la Sociedad Pathfinder/Starfinder en Madrid y allí jugar, no hace falta que te crees el personaje ya que allí te podrán dejar personajes pregenerados para que pruebes el juego y ayudarte a crearte tu personaje, pero si quieres crear tu personaje, te recomendamos que eches un vistazo a los enlaces de interés que hay mas abajo para descargar la ficha de perosnaje, ver la guia de la sociedad (las reglas para participar en el juego organizado) y mucho más.</p>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default CommonResources;
