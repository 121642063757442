import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

function Gallery({ imagenes, titulo }) {
  // Configuración responsiva
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4, // Mostrar 3 imágenes en escritorio
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2, // Mostrar 2 imágenes en tablet
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // Mostrar 1 imagen en móvil
    },
  };

  return (
    <section className="py-5">
      <div className="container">
        <h2 className="text-center mb-4">{titulo}</h2>
        <Carousel
          responsive={responsive}
          autoPlay
          infinite
          showDots
          autoPlaySpeed={3000}
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {imagenes.map((imagen, index) => (
            <div key={index}>
              <img
                src={imagen}
                alt={`Imagen ${index + 1}`}
                style={{ width: "100%", height: "auto", borderRadius: "10px" }}
                className="carousel-image"
              />
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default Gallery;
