import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Card,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { Save as SaveIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";

const CreateCharacterForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    paizo_id: "",
    level: 1,
    experience: 0,
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/characters/create/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      navigate("/characters");  // Redirigir a la lista de personajes después de crear
    } catch (err) {
      setError("Error al crear el personaje. Inténtalo de nuevo más tarde.");
      console.error(err);
    }
  };

  return (
    <Container maxWidth="md" className="mt-3">
      <Card className="p-4 shadow-sm">
        <Typography variant="h4" className="text-center mb-4">
          Crear Personaje
        </Typography>

        {error && (
          <Alert severity="error" className="mb-4">
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            label="Nombre"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />

          <TextField
            label="Paizo ID"
            name="paizo_id"
            value={formData.paizo_id}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          <TextField
            type="number"
            label="Nivel"
            name="level"
            value={formData.level}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />

          <TextField
            type="number"
            label="Experiencia"
            name="experience"
            value={formData.experience}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />

          <div className="d-flex justify-content-between mt-4">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/dashboard")}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              Guardar
            </Button>
          </div>
        </form>
      </Card>
    </Container>
  );
};

export default CreateCharacterForm;