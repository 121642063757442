import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Collapse } from "react-bootstrap";
import RoleSelectionModal from "../components/ModalRole"; // Modal para seleccionar rol

const SessionsPage = () => {
  const { id } = useParams(); // Obtener el parámetro 'id' de la URL
  const [partidas, setPartidas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventName, setEventName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedPartida, setSelectedPartida] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Estado para autenticación

  const navigate = useNavigate();
  const location = useLocation();

  const [openSections, setOpenSections] = useState({
    "Sábado Mañana": true,
    "Sábado Tarde": true,
    "Domingo Mañana": true,
    "Domingo Tarde": true,
  });

  const toggleSection = (turno) => {
    setOpenSections({ ...openSections, [turno]: !openSections[turno] });
  };

  const turnos = ["Sábado Mañana", "Sábado Tarde", "Domingo Mañana", "Domingo Tarde"];

  // Llamada a la API al montar el componente
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    setIsLoggedIn(!!token); // Establecer el estado de autenticación

    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/partidas/${id}`);
        const data = [];       

        // Extraer el nombre del evento
        setEventName(response.data.event_name);

        // Formatear los datos para que sean más manejables
        turnos.forEach((turno) => {
          if (response.data[turno]) {
            response.data[turno].forEach((partida) => {
              data.push(partida);
            });
          }
        });

        setPartidas(data);
        setLoading(false); // Terminar la carga de datos
      } catch (error) {
        console.error("Error al obtener las partidas:", error);
        setLoading(false); // Terminar la carga de datos con error
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div className="text-center my-5">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const handleApuntarse = (partida) => {
    if (!isLoggedIn) return; // Prevenir acciones si no está logueado
    setSelectedPartida(partida);
    setShowModal(true);
  };

  const handleSubmitRole = async (partidaId, role) => {
    if (!isLoggedIn) return; // Prevenir acciones si no está logueado
    try {
      const token = localStorage.getItem("access_token"); // Obtener el token
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/partidas/${partidaId}/inscribirse/`,
        { role }, // Cuerpo de la solicitud
        {
          headers: {
            Authorization: `Bearer ${token}`, // Enviar el token correctamente
          },
        }
      );
      console.log(response.data.detail);
      window.location.reload();
    } catch (error) {
      console.error("Error al inscribirse:", error);
      alert(error.response.data.detail || "Error al inscribirse.");
    }
  };

  const handleDesapuntarse = async (partidaId) => {
    try {
      const token = localStorage.getItem("access_token"); // Obtener el token
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/partidas/${partidaId}/inscribirse/`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Token de autenticación
          },
        }
      );
      console.log(response.data.detail);
      window.location.reload(); // Refrescar la página para actualizar el estado
    } catch (error) {
      console.error("Error al desapuntarse:", error);
      alert(error.response?.data?.detail || "Error al desapuntarse.");
    }
  };

  return (
    <div className="container mt-4">
      <h1 className="mb-4 text-center">Inscripciones del Evento: {eventName}</h1>
      {turnos.map((turno) => {
        const partidasTurno = partidas.filter((p) => p.turno === turno);
        if (partidasTurno.length === 0) return null;

        return (
          <div key={turno} className="mb-3 row mx-1">
            {/* Título de la sección */}
            <h2
              className="bg-dark text-white p-2 mb-1 rounded d-flex justify-content-between align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => toggleSection(turno)}
            >
              {turno}
              <span>{openSections[turno] ? "▲" : "▼"}</span>
            </h2>

            {/* Sección desplegable */}
            <Collapse in={openSections[turno]}>
              <div className="col-12">
                <div className="row ">
                  {partidasTurno.map((partida) => (
                    <div key={partida.id} className="card mb-2 shadow-sm col-12 col-md-6">
                      <div className="card-body p-3">
                        {/* Nombre y sistema */}
                        <h5 className="mb-1">{partida.nombre}</h5>
                        <p className="text-muted mb-0">
                          Sistema: {partida.sistema_legible}
                        </p>
                        <p className="text-muted mb-0">
                          Niveles: {partida.niveles}
                        </p>
                        <hr className="mb-1 mt-1" />

                        {/* GM asignado */}
                        <p className="mb-0">
                          <strong>Gamemaster:</strong>{" "}
                          {partida.gm ? partida.gm.username : ""}
                        </p>

                        {/* Jugadores Confirmados */}
                        <p className="mb-0">
                          <strong>
                            Jugadores {partida.jugadores.length}/{partida.num_jugadores}:
                          </strong>
                        </p>
                        <div className="row gx-2">
                          {partida.jugadores.map((jugador, index) => (
                            <div key={`jugador-${index}`} className="col-6">
                              {index + 1}. {jugador.username}
                            </div>
                          ))}
                        </div>

                        {/* Lista de espera */}
                        <p className="mb-0 mt-0 text-muted">
                          <strong>Lista de espera {partida.lista_espera.length}/{8-partida.num_jugadores}:</strong>
                        </p>
                        <div className="row gx-2">
                          {partida.lista_espera.map((espera, index) => (
                            <div key={`espera-${index}`} className="col-6 text-muted">
                              {index + 1}. {espera.username}
                            </div>
                          ))}
                        </div>

                        {/* Botones */}
                        <div className="mt-3">
                          <button className="btn btn-sm btn-dark me-2" onClick={() => handleApuntarse(partida)} disabled={!isLoggedIn}>Apuntarse</button>
                          <button className="btn btn-sm btn-danger" onClick={() => handleDesapuntarse(partida.id)} disabled={!isLoggedIn}>Desapuntarse</button>
                        </div>
                        {!isLoggedIn && (
                            <p className="text-danger mt-2">
                              Debes <Link to={`/login?redirect=${location.pathname}`}>iniciar sesión</Link> para inscribirte.
                            </p>
                          )}
                        <hr className="mb-1 mt-2" />

                        {/* Disclaimer */}
                        <p className="text-muted mt- mb-0" style={{ fontSize: "0.85em" }} >
                          Los últimos 2 huecos (sin contar Lista de espera) son
                          prioritarios para personas nuevas. Puedes apuntarte pero sin
                          preferencia.
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Collapse>
          </div>
        );
      })}

      {/* Modal de selección de rol */}
      {selectedPartida && (
        <RoleSelectionModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onSubmit={handleSubmitRole}
          partida={selectedPartida}
        />
      )}
    </div>
  );
};

export default SessionsPage;
