import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Button,
  Collapse,
  IconButton,
  Box,
} from "@mui/material";
import { Add as AddIcon, KeyboardArrowDown as ArrowDownIcon, KeyboardArrowUp as ArrowUpIcon,AutoStories as AutoStoriesIcon, } from "@mui/icons-material";

const CharacterTable = ({ characters, loading }) => {
  const [expandedRow, setExpandedRow] = useState(null); // Estado para controlar la fila expandida

  const handleRowClick = (id) => {
    if (expandedRow === id) {
      setExpandedRow(null); // Si ya está expandida, la contraemos
    } else {
      setExpandedRow(id); // Expandimos la fila seleccionada
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <CircularProgress />
        <Typography variant="body1" className="mt-2">
          Cargando personajes...
        </Typography>
      </div>
    );
  }

  if (characters.length === 0) {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "16px",
          }}
        >
          <Button
            component={Link}
            to="/characters/create"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            Crear nuevo personaje
          </Button>
        </div>
        <Typography variant="body1" className="text-center mt-5">
          No tienes personajes creados.
        </Typography>
      </div>
    );
  }

  return (    
<div>
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      marginBottom: "16px",
    }}
  >
    <Button
      component={Link}
      to="/characters/create"
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      style={{ fontSize: "12px", fontWeight: "bold" }}
    >
      Crear nuevo personaje
    </Button>
  </div>
    <TableContainer component={Paper} className="mt-4 shadow-sm">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" fontWeight="bold">
                Nombre
              </Typography>
            </TableCell>
            <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>
              <Typography variant="subtitle1" fontWeight="bold">
                Paizo ID
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" fontWeight="bold">
                Nivel
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" fontWeight="bold">
                XP
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" fontWeight="bold">
                Acciones
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {characters.map((char) => (
            <React.Fragment key={char.id}>
              <TableRow
                hover
                onClick={() => handleRowClick(char.id)}
                sx={{ cursor: "pointer" }}
              >
                <TableCell>
                  <Typography variant="body1">{char.name}</Typography>                  
                </TableCell>
                <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>
                  <Typography variant="body1">{char.paizo_id}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{char.level}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{char.experience}</Typography>
                </TableCell>
                <TableCell>
                  <IconButton
                    component={Link}
                    to={`/characters/${char.id}`}
                    onClick={(e) => e.stopPropagation()} // Evita que el clic en el botón expanda la fila
                    sx={{
                      color: "primary.main",
                      "&:hover": {
                        backgroundColor: "rgba(25, 118, 210, 0.1)",
                      },
                    }}
                  >
                    <AutoStoriesIcon />
                  </IconButton>                  
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    padding: 0,
                    border: 0,
                    display: expandedRow === char.id ? "table-cell" : "none",
                  }}
                >
                  <Collapse in={expandedRow === char.id}>
                    <Box sx={{ padding: 2, backgroundColor: "background.paper" }}>
                      <Typography variant="body1">
                        <strong>Paizo ID:</strong> {char.paizo_id}
                      </Typography>                      
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

export default CharacterTable;