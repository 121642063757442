import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Card, Typography, TextField, Checkbox, FormControlLabel, Button, Alert, Autocomplete } from "@mui/material";
import { Save as SaveIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";

const AddPlaythroughForm = () => {
  const { characterId, playthroughId } = useParams(); // playthroughId es opcional
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    scenario: "",
    is_player: false,
    is_gm: false,
    experience_gained: 0,
    notes: "",
  });
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isEdit, setIsEdit] = useState(false); // Determina si es edición o creación

  useEffect(() => {
    const fetchScenarios = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/scenarios/`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
        });
        setScenarios(response.data);

        // Si estamos editando, cargamos los datos de la crónica
        if (playthroughId) {
          setIsEdit(true); // Modo edición activado
          const playthroughResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/characters/${characterId}/scenarios/${playthroughId}/`,
            {
              headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
            }
          );
          const playthroughData = playthroughResponse.data;

          // Actualizamos el formulario con los datos de la crónica
          setFormData({
            scenario: playthroughData.scenario,
            is_player: playthroughData.is_player,
            is_gm: playthroughData.is_gm,
            experience_gained: playthroughData.experience_gained,
            notes: playthroughData.notes,
          });
        }
      } catch (err) {
        setError("Error al cargar los escenarios o los datos de la crónica.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchScenarios();
  }, [characterId, playthroughId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isEdit) {
        // Actualizar una crónica existente
        await axios.put(
          `${process.env.REACT_APP_API_URL}/characters/${characterId}/scenarios/${playthroughId}/`,
          formData,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
          }
        );
      } else {
        // Crear una nueva crónica
        await axios.post(
          `${process.env.REACT_APP_API_URL}/characters/${characterId}/scenarios/add/`,
          formData,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
          }
        );
        alert("Crónica creada con éxito.");
      }
      navigate(`/characters/${characterId}`);
    } catch (err) {
      setError("Error al guardar los datos. Inténtalo de nuevo más tarde.");
      console.error(err);
    }
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <p>Cargando datos...</p>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className="mt-3">
      <Card className="p-4 shadow-sm" style={{ border: "1px solid #dadada" }}>
        <Typography variant="h4" className="text-center mb-4">
          {isEdit ? "Editar Crónica" : "Añadir Crónica"}
        </Typography>

        {error && (
          <Alert severity="error" className="mb-4">
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Autocomplete
            options={scenarios}
            getOptionLabel={(option) =>
              option.number
                ? `#${option.season}-${option.number}: ${option.name}`
                : `#${option.season}: ${option.name}`
            }
            value={
              scenarios.find((option) => option.id === formData.scenario) || null
            }
            onChange={(event, value) =>
              setFormData({ ...formData, scenario: value ? value.id : "" })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecciona un escenario"
                fullWidth
                margin="normal"
                required
              />
            )}
          />

          <FormControlLabel
            control={
              <Checkbox
                name="is_player"
                checked={formData.is_player}
                onChange={handleChange}
              />
            }
            label="¿Jugador?"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="is_gm"
                checked={formData.is_gm}
                onChange={handleChange}
              />
            }
            style={{ float: "right" }}
            label="¿GM?"
          />

          <TextField
            type="number"
            label="XP Ganada"
            name="experience_gained"
            value={formData.experience_gained}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />

          <TextField
            label="Notas"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />

          <div className="d-flex justify-content-between mt-4">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(`/characters/${characterId}`)}
            >
              Atrás
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              {isEdit ? "Editar" : "Guardar"}
            </Button>
          </div>
        </form>
      </Card>
    </Container>
  );
};

export default AddPlaythroughForm;
